.searchItems {
  margin: 5% 0% 0% 5%;
  width: 90%;
  height: 30px;
}

.options {
  top: -33px;
  right: 0;
  width: 130px;
}

.listGroup {
  min-height: 270px;
  overflow-x: hidden;
}

.ots-card-height{
  height: 95vh;
}

.mobile-ots-card-height{
  height: 115vh;
}

.grid-bottom {
  margin-bottom:-1.3rem;
}
.moblie-grid-bottom {
  margin-bottom: -3.3rem;
}

.sidebar-width {
  width: 30%;
}

.preview-width {
  width: 60%;
}

.preview-width-loop-rma-view-panel{
  width: 75%;
}

.preview-width-mobile {
  width: 100%;
}

.preview-z-index {
  z-index: 10000 !important;
}

.preview-head-height {
  height: 45px;
}

.sidebar-bg {
  background: #303c54 !important;
}

.font-20 {
  font-size: 20px;
}

.height-26 {
  height: 26px;
}

.height-56 {
  height: 56vh;
}

.grid-dropdown .dropdown-content {
  max-height: none !important;
  overflow: unset;
  padding: 0px !important;
}

.grid-dropdown .search input {
  background-color: #fff !important;
}

.height-100 {
  height: 100vh;
}

.height-8 {
  height: 8%;
}

.height-69 {
  height: 69%;
}

.viewFields {
  color: #ffffffde;
}

.viewFields:hover {
  color: #ffffffde;
}

.options-boarder {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.dropdown-option-dark {
  background-color: #3c3c3d;
}

.grid-option .dropdown-item.disabled {
  color: #abababde;
}

.grid-option .dropdown-item {
  color: #ffffffde;
}

.grid-option .dropdown-item:hover {
  background-color: #888888;
}



.tab-background {
  background-color: #eeeeee;
}

.dark-tab-background {
  background-color: #6e6e6e;
}

.tab-separator {
  font-size: 27px;
  margin-top: -7px;
}

.pagination-position {
  position: absolute;
  width: -webkit-fill-available;
  top: 92%;
}

.mobile-pagination-position {
  top: 87% !important;
}

.mobile-card-height {
  height: 100vh;
  width: 105%;
  margin-left: -3%;
}

.draggable-item-bg {
  background-color: #ebf0f6;
}

.draggable-item-dark-bg {
  background-color: #bdc7ff !important;
}

.rmsc .select-item {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-bottom: 0px;
}

.custom-modal-body {
  transition: max-height 0.3s ease, padding 0.3s ease;
  overflow-y: auto;
}

.custom-modal-body.expanded {
  max-height: calc(90vh - 120px); /* Adjust based on your needs */
  padding-bottom: 200px; /* This creates space for the dropdown */
  height: 67vh;
}

.multiselect-container {
  position: relative;
}

.custom-multiselect .dropdown-content {
  position: absolute;
  z-index: 1050;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.mobile-grid-height {
  height: 72vh;
  margin-top: -0.8rem;
}

.grid-height {
  height: 64vh;
  margin-top: -1rem;
}

.grid-side-list {
  width: 135px;
}

.loopRMA-grid-height{
  height: 76vh;
    margin-top: -0.8rem;
}
.loopRMA-Card-height{
  height: 76vh;
}
.search-error {
  position: absolute;
  top: 60%;
}

.iconstyle {
  right: 49px !important;
  top: 30% !important;
  z-index: 3;
}

.padding-right-35 {
  padding-right: 35px;
}

.padding-right-25 {
  padding-right: 25px;
}

.SearchCrossIcon {
  position: absolute;
  left:85%;
  top: 30%;
  z-index: 3;
}

.customIcon {
  position: absolute;
  top: 30%;
  z-index: 3;
  right : 37% ;
}
.ig-suffix-content > div {
  margin-left: 12px;
}

.opacity-0 {
  opacity: 0;
}

.button-text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.padding-top-2 {
  padding-top: 2px;
}

.warehouse-grid-style {
  margin-top: -1rem !important;
}

.warehouse-tab-style {
  border-top-right-radius: 7px;
  border-bottom: none !important;
  border-color: #fff !important;
}

.custom-multiselect .item-renderer {
  white-space: nowrap;
}

.favorite-color {
  color: #f6b615;
}
.alert-danger {
  color: #772b35 !important;
}
.custom-width {
  width: 350px !important;
  margin-left: -11px;
}
.custom-width_OR{
  width: 350px !important;
  margin-left: -11px;

}

.cross_icon {
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.input-wrapper {
  position: relative;
}
.input-with-icon {
  padding-right: 30px;
}

.cross_icon_Mobile{
  position: absolute;
  right: 20%;
  top: 30%;
  z-index: 3;
}
.POcross_icon_Mobile{
  position: absolute;
  right: 13%;
  top: 30%;
  z-index: 3;
}
.Date_Cross_Icon{
  position: absolute;
  top: 30%;
  z-index: 3;
  left: 310px;
}
.Date_Cross_Icon_Mobile{
  position: absolute;
  right: 21%;
  top: 30%;
  z-index: 3;
}
.Date_Cross_Icon_ORMobile{
  position: absolute;
  left:81%;
  top: 30%;
  z-index: 3;
}
.Date_Cross_Icon_OR{
  position: absolute;
  top: 30%;
  z-index: 3;
  left: 310px;
}

// Grid Filters CSS
.filter-expression {
  padding: 0px 2px;
  border: 1px solid green;
  margin-left: 5px;
  border-radius: 0.2rem;
  font-size: 12px;
}

.operator-display{
    color: white;
    padding: 0px 2px;
    border: 2px solid #116530;
    border-radius: 0.2rem;
    background-color: #18A558;
    padding-bottom: 0px;
    margin: 1px 2px 1px 2px;
    font-size: 12px;
    cursor: pointer;
}

.active-group{
  color: red;
  font-size: 22px !important;
}

.next-actions-pannel {
  text-align: -webkit-right;
  padding-right: "0px";
}

.expression-active {
  background-color: lightgreen;
  border: 1px solid darkgreen;
}

.text-align-start {
  text-align: start;
}

@media (min-width: 450px) {
  .iconstyle {
    right: 49px;
  }
  
}
@media only screen and (max-width: 600px) {
  .SearchCrossIcon{
    left:64%
  }
  .custom-width{
    width: 270px !important;
  }
  .custom-width_OR{
    width:271px !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .SearchCrossIcon{
    left:75%
  }
  .custom-width{
    width: 270px !important;
  }
}

.pickTicketDropdown{
  width:36%
}

.loader-height {
  height: 50vh;
}

.nav-tabs .nav-link.active {
  color: #321fdb;
  background-color: #fff;
  // height: 102% !important;
  border-bottom: none;
}

.nav-link-inActive {
  color: #768192;
  background-color: #ebedef;
  height: 100% !important;
}

.dark-nav-link-inActive {
  color: #e0e0e0 !important;
  background-color: #6e6e6e;
  height: 100% !important;
}
.dark-nav-link-inActive:hover {
  color: #321fdb !important;
}

.dark-nav-link-Active {
  background-color: #24252f !important;
}

.view-tab-dark-inActive {
  background-color: #6a6a6a;
  color: #e0e0e0 !important;
  height: 100% !important;
}

@media screen and (min-width: 480px) and (max-width: 1024px) and (orientation: landscape) {
  .mobile-card-height {
    height: 135vh !important;
  }
  .card-height {
    height: 105vh !important;
  }
  .mobile-pagination-position {
    top: 82% !important;
  }
  .pagination-position {
    top: 87%;
  }
}
.ScrollHide .ig-data-grid > div:nth-last-child(-n+2) {
  z-index: 99 !important;
}


@media screen and (max-width: 380px) {
  .otsCardHeight_Moblie{
    height: 116vh;
  }
}

@media screen and (min-width:381px) and (max-width:767px){
  .otsCardHeight_Moblie{
    height: 108vh;
  }
  .customIcon {
    right: 21% !important;
  }
  
}
@media screen and (min-width:767px) and (max-width:1024px){
  .ots_pagination_desktop{
    top:87%;
  }
}

.igx-excel-filter__icon--filtered igx-icon {
  color: #27a7a9 !important;
}

.grid-class :is(.igx-grid, .igx-grid--compact) {
  --cell-active-border-color: "";
  --cell-selected-background: "";
  --cell-selected-text-color: "";
  --cell-selected-within-background: "";
  --cell-selected-within-text-color: "";
}

.gridRowHeight .igx-grid__td {
  height: 50px;
}

.grid-dark-mode .igx-input-group {
  --focused-text-color: #ffffffcc;
  --filled-text-color: #ffffffcc;
}

.dropdown-menu {
  z-index: 1050;
}

.grid-dark-mode .igx-button-group__item {
  color: #ffffffcc;
}

.grid-dark-mode .igx-button-group__item:hover {
  color: #3b424a;
}

.grid-dark-mode .igx-grid__td--pinned {
  background-color: #2a2b36;
}

.grid-dark-mode .igx-grid__cbx-selection {
  background-color: #292a35;
}

.grid-dark-mode :is(.igx-grid, .igx-grid--compact) {
  --ig-grid-filtering-row-background: #2a2b36;
  --ig-button-background: #2a2b36;
  --ig-button-foreground: #ffffffcc;
  --ig-button-hover-foreground: #d7cccc;
  --ig-list-background: #2a2b36;
  --ig-list-item-background: #2a2b36;
  --ig-list-item-background-hover: #c2b1b1bd;

  --ig-grid-excel-filtering-header-foreground:#ffffffcc;
  --ig-grid-excel-filtering-subheader-foreground:#ffffffcc;
  --ig-grid-excel-filtering-actions-disabled-foreground:#7f7f7f;
  --ig-grid-excel-filtering-actions-foreground:#ffffffcc;

  --ig-checkbox-label-color: #ffffffcc;

  --ig-drop-down-background-color: #2a2b36;
  --ig-drop-down-item-text-color: #ffffffcc;
  --ig-drop-down-item-background: #2a2b36;
  --ig-drop-down-item-text-color: #ffffffcc;
  --ig-drop-down-focused-item-background: #c2b1b1bd;

  --grouparea-color: #ffffffcc;
  --grouparea-background: #2a2b36;
  --header-background: #2a2b36;
  --header-text-color: #ffffffcc;
  --header-selected-background: #2a2b36;
  --header-selected-text-color: #ffffffcc;
  --ghost-header-background: #2a2b36;
  --ghost-header-text-color: #ffffffcc;
  --grid-border-color: #2a2b36;
  --content-background: #2a2b36;
  --content-text-color: #ffffffcc;
  --row-odd-background: #2a2b36;
  --row-even-background: #2a2b36;
  --row-odd-text-color: #ffffffcc;
  --row-even-text-color: #ffffffcc;
  --row-selected-background: #1919194d;
  --row-selected-hover-background: #4c4a4a87;
  --row-hover-background: #6e6e6e87;
  --row-selected-text-color: #ffffffcc;
  --row-hover-text-color: #eeeeeecc;
  --row-selected-hover-text-color:#f1f1f1;
}

.igx-grid__outlet div:nth-of-type(1) .igx-overlay__content {
  transform: translateY(12.1px) !important;
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  .igx-grid__outlet div:nth-of-type(1) .igx-overlay__content {
    left :28px !important;
  }
}
.custom-width .react-datepicker-wrapper .react-datepicker__input-container .form-control {
  padding-left: 0;
}

.disabledCheck > .igx-grid__cbx-selection > .igx-grid__cbx-padding > .igx-checkbox {
  background: #e1e1e1;
  pointer-events: none;
}

.darkDisabledCheck > .igx-grid__cbx-selection > .igx-grid__cbx-padding > .igx-checkbox {
  background: #797979;
  pointer-events: none;
}
@media screen and (min-width: 375px) and (max-width: 767px) {
  .igx-grid__outlet div:nth-of-type(1) .igx-overlay__content {  
    left :28px !important;
  }
}

