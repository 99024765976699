// Here you can add other styles
.Mt-10{
    margin-top: 10px;
}
.w-10 {
    width: 10%
}
.w-15 {
    width: 15%
}
.w-20 {
    width: 20%
}
.w-25 {
    width: 25%
}
.w-30 {
    width: 30%
}
.w-35 {
    width: 35%
}
.w-40 {
    width: 40%
}
.w-45 {
    width: 45%
}
.w-55 {
    width: 55%
}
.w-60 {
    width: 60%
}
.w-65 {
    width: 65%
}
.w-70 {
    width: 70%
}
.w-75 {
    width: 75%
}
.w-inherit{
    width:inherit !important;
}
.scrollbar-width-thin {
    scrollbar-width: thin;
}
.overflow-y-clip {
    overflow-y: clip !important;
    overflow-y: -webkit-paged-y !important;
}
.text-decoration-line-through {
    text-decoration: line-through;
}
.white-space-pre-wrap {
    white-space: pre-wrap;
}
.overflow-x {
    overflow-x: auto;
}
.overflow-y-clip ::-webkit-scrollbar {
    height: 5px;
}
.end-0 {
    right: 0;
}

/* Custom style for the select element */
.grid-custom-select {
    color: #4f5d73;
    background-color: #ced2d8;
    width: 100px;
}

/* Custom style for disabled options */
.grid-custom-select option:disabled {
    color: #8a93a2; /* Change this to your desired color */
}
