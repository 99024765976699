//AMultiSelect component style start

// .dark_multiselect {
//     .dropdown-container {
//         background: #2f303a !important;
//         border-color: #ffffff1a !important;
//         color: #cbcbce !important;
//     }

// }
// .dropdown-content {
//   max-height: 200px !important; /* Adjust the height as needed */
//   overflow-y: hidden;
// }

.dark_multiselect_disabled {
    .dropdown-container {
        background: #ffffff1a !important;
        border-color: #ffffff1a !important;
        color: #cbcbce !important;
    }
}

.multiselect_disabled {
    .dropdown-heading {
        color: #0000008c;
    }

    .dropdown-container {
        background-color: #e4e7ea !important;
        opacity: 1 !important;
        border: #e4e7ea !important;
        color: #ffffff1a !important;
    }

    .dropdown-container:focus {
        background-color: #e4e7ea !important;
        opacity: 1 !important;
        border: #e4e7ea !important;
        color: #ffffff1a !important;
    }
}
.rmsc .dropdown-content{
    max-height: 200px !important;
    z-index: 99999 !important;
}

.rmsc .search-clear-button {
    background-color: #fff !important;
}

.rmsc .dropdown-content div:nth-child(1) div ul{
    width:  100% !important;
}
.rmsc .dropdown-content div:nth-child(1) div li{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
.dark_multiselect {
    .dropdown-content {
        color: lightgray !important;
    }

    .dropdown-content .panel-content {
        background-color: #2f303a !important;
    }

    .select-item.selected {
        background-color: #4e4f60 !important;
    }

    .select-item:hover {
        background: #4e4f60 !important;
    }


    // ::-webkit-scrollbar {
    //     width: 12px !important;
    //     background: #c1c1c1 !important;
    // }

    /* Track */
    // ::-webkit-scrollbar-track {
    //     box-shadow: inset 0 0 12px #4e4f60;
    //     border-radius: 10px;
    // }

    /* Handle */
    // ::-webkit-scrollbar-thumb {
    //     background: #c1c1c1 !important;
    //     border-radius: 12px;
    // }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #2d2e38;
    }

    .dropdown-container {
        background: #2f303a !important;
        border-color: #ffffff1a !important;
        color: #cbcbce !important;
    }
}

.mail-Height .dropdown-container .dropdown-content .panel-content .options {
    max-height: 170px !important; 
}

@media (max-width: 768px){

    .mail-Height .dropdown-content {
        max-height: 196px !important;
        overflow: hidden !important;
    }
    .scheduleEditor{
        height: 72vh;
    }
}


//AMultiSelect component style  end

.alert-button {
    margin-right: -10px;
}
.closebtn .close{
    color: black;
}
.closebtn .close:hover{
    color:black !important;
}

.PhotoView-Slider__BannerWrap {
    background-color: transparent !important;
    justify-content: space-evenly !important;
    display:none !important;
}

.PhotoView-Z-Index {
    z-index: 10000 !important;
}

.PhotoView-Slider__Counter {
    color: black !important;
    visibility: hidden !important;
}

.PhotoView-Slider__toolbarIcon {
    fill: #2d2e38 !important;
}

.PhotoView-Slider__ArrowLeft,
.PhotoView-Slider__ArrowRight {
    position: initial !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
}
.PhotoView-Slider__ArrowLeft svg,
.PhotoView-Slider__ArrowRight svg,
.PhotoView-Slider__ArrowLeft-mobile svg,
.PhotoView-Slider__ArrowRight-mobile svg{
    border-radius: 50rem !important;
    padding: 10px 15px 10px 15px !important;
}

.PhotoView-Slider__Overlay {
    position: absolute;
    z-index: 99;
    bottom: 10px;
    width: 100% !important;
}

.PhotoView-Slider__Overlay .indexIndent {
    bottom: 6px;
}

.PhotoView__PhotoBox {
    top: 0px;
    left: 19px;
    bottom: 10px;
}

.PhotoView__Photo {
    width: calc((100vh * 16) / 9);
    height: calc((100vw * 9) / 16);
    object-fit: cover;
    // width: auto !important;
}

.dots {
    height: 7px;
    width: 7px;
    background-color: rgb(67, 67, 67);
    border-radius: 50%;
}

.dots-selected {
    height: 10px;
    width: 10px;
    background-color: #fff;
    border-radius: 50%;
}

.arrowButtons {
    width: 138vw;
    position: absolute;
    bottom: 800%;
}

.PhotoView-Slider__ArrowLeft-mobile,
.PhotoView-Slider__ArrowRight-mobile {
    align-items: center;
    bottom: 0;
    cursor: pointer;
    display: flex;
    height: 75px;
    justify-content: center;
    margin: auto;
    opacity: .75;
    top: 0;
    transition: opacity .2s linear;
    -webkit-user-select: none;
    user-select: none;
    width: 70px;
    z-index: 20;
}

.PhotoView-Slider__ArrowLeft-mobile svg,
.PhotoView-Slider__ArrowRight-mobile svg {
    fill: #fff;
    background: rgba(0, 0, 0, .3);
    box-sizing: content-box;
    height: 24px;
    padding: 10px;
    width: 24px;
}

.PhotoView-Slider__BannerRight{
    margin-right: 4% !important;
    display:none;
}

.lrg_Img
{
    height:71px !important;
    bottom:800%;
    // right:-645%;
}

.sml_Img
{
    height:71px !important;
    bottom:800%;
    // right:-189%;
}
.xs_Img
{
    height:71px !important;
    bottom:800%;
    // right:-157%;
}

.width-100percent {
    width: 100%;
}

.cpl-25percent {
    padding-left: 25%;
}

@media (max-width:768px)  {
    .rmsc .dropdown-content{
        max-height: 200px !important;
        overflow: scroll;
    }
    .RoleDropdown .dropdown-content {
        overflow: visible;
    }
    .PhotoView-Slider__BannerWrap {
        background-color: transparent !important;
        justify-content: space-between !important;
        top: 21%;
        display:none !important;
    }

    .PhotoView-Slider__ArrowLeft,
    .PhotoView-Slider__ArrowRight {
        position: initial !important;
        display: block !important;
    }

    .PhotoView__Photo {
        object-fit: cover;
    }

    .PhotoView__PhotoBox {
        top: 0px;
        left: 0px;
    }

    .PhotoView-Slider__Overlay {
        position: absolute;
        z-index: 999;
        bottom: 22%;
    }
}

@media (max-width:480px)  {
    .PhotoView-Slider__BannerWrap {
        background-color: transparent !important;
        justify-content: space-between !important;
        top: 17% !important;
        display:none !important;
    }

    .PhotoView__Photo {
        object-fit: cover;
    }

    .PhotoView__PhotoBox {
        top: 0px;
        left: 0px;
    }
}

/* Media query for smaller mobile screens */
@media (max-width: 399px) {

    /* Add styles for smaller mobile screens here */
    .PhotoView-Slider__BannerWrap {
        background-color: transparent !important;
        justify-content: space-between !important;
        top:21%;
        display:none !important;
    }
    .PhotoView__Photo {
        object-fit: cover;
    }
    .PhotoView__PhotoBox {
        top: 0px;
        left: 0px;
    }
    .arrowButtons {
        width: 138vw;
        position: absolute;
        bottom: 1100%;
    }
}


@media (max-width: 380px) {
    /* Add styles for smaller mobile screens here */
    .PhotoView-Slider__BannerWrap {
        background-color: transparent !important;
        justify-content: space-between !important;
        top: 15%;
        display:none !important;
    }
    .PhotoView__Photo {
        object-fit: cover;
    }
    .PhotoView__PhotoBox {
        top: 0px;
        left: 0px;
    }
    .arrowButtons {
        width: 138vw;
        position: absolute;
        bottom: 600%;
    }
    .PhotoView-Slider__Overlay {
        bottom: 16%;
    }
}
@media (max-width: 360px) {
    /* Add styles for smaller mobile screens here */
    .PhotoView-Slider__BannerWrap {
        background-color: transparent !important;
        justify-content: space-between !important;
        top: 23%;
        display:none !important;
    }
    .PhotoView__Photo {
        object-fit: cover;
    }
    .PhotoView__PhotoBox {
        top: 0px;
        left: 0px;
    }
    .arrowButtons {
        width: 138vw;
        position: absolute;
        bottom: 600%;
    }
    .PhotoView-Slider__Overlay {
        bottom: 24%;
    }
}

@media (max-height: 769px) and (max-width:768px) {
    .PhotoView-Slider__BannerWrap {
        background-color: transparent !important;
        justify-content: space-between !important;
        top: 19% !important;
        display:none !important;
    }
    .PhotoView-Slider__Overlay {
        bottom: 21%;
    }
}
@media (max-height: 660px) and (max-width:768px) {
    .PhotoView-Slider__BannerWrap {
        background-color: transparent !important;
        justify-content: space-between !important;
        top: 10% !important;
        display:none !important;
    }
    .PhotoView-Slider__Overlay {
        bottom: 14%;
    }
}
@media (max-height: 769px) and (max-width:375px) {
    .PhotoView-Slider__BannerWrap {
        background-color: transparent !important;
        justify-content: space-between !important;
        top: 14% !important;
        width: 95% !important;
        display:none !important;
    }
    .PhotoView-Slider__Overlay {
        bottom: 21%;
    }
}
@media (max-height: 1180px) and (max-width:820px) {
    .PhotoView-Slider__BannerWrap {
        background-color: transparent !important;
        justify-content: space-between !important;
        display:none !important;
    }
}
@media (max-height: 780px) and (max-width:420px) {    
    .PhotoView-Slider__BannerWrap {
        background-color: transparent !important;
        justify-content: space-between !important;
        top: 10% !important;
        width: 95% !important;
        display:none !important;
    }
    .PhotoView-Slider__Overlay {
        bottom: 14%;
    }
}

@media (max-height : 769px) and (max-width: 1368px)
{
    .PhotoView-Slider__BannerRight{
        margin-right: 0% !important;
        display:none;
    }
    .PhotoView-Slider__ArrowRight 
    {
        margin-right: 45% !important;
    }
    .PhotoView-Slider__Arrow 
    {
        margin-right: 40% !important;
    }

}