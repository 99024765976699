body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
   background-color: #181924;
}

.no-scroll{
  position: relative !important;
  overflow-y: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.login-bg{
  background-color: #ebedef;
}

.icon{
  position: absolute;
    right: 20px;
    margin-top: 10px;
}
.iconStyle{
  top:1px;
  position: absolute;
    right: 27px;
    margin-top: 10px;
}

#Botsonic-wrapper {
  visibility: hidden;
}